/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "consent/common/bff/api/v1/consent.proto" (package "consent.common.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Date } from "../../../../../google/type/date";
import { Timestamp } from "../../../../../google/protobuf/timestamp";
/**
 * Contains the contents of a consent to be rendered.
 *
 * @generated from protobuf message consent.common.bff.api.v1.ConsentContent
 */
export interface ConsentContent {
    /**
     * The title of the consent displayed to the user.
     *
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * Modules containing consent content. This repeated element will allow for
     * future content types with arbitrary order.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.ContentModule content_modules = 2;
     */
    contentModules: ContentModule[];
    /**
     * Contents of the button used for submitting a consent.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.Button submit_button = 3;
     */
    submitButton?: Button;
    /**
     * Contents of the button used for declining a consent.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.Button decline_button = 4;
     */
    declineButton?: Button;
    /**
     * Contents of the dialog popup for declining a consent.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.DialogContent disagree_dialog_content = 5;
     */
    disagreeDialogContent?: DialogContent;
    /**
     * Content relating to reviewing a signed consent.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.SignedConsentReviewContent signed_consent_review_content = 7;
     */
    signedConsentReviewContent?: SignedConsentReviewContent;
    /**
     * Content relating to withdrawing a signed consent.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.SignedConsentWithdrawContent signed_consent_withdraw_content = 8;
     */
    signedConsentWithdrawContent?: SignedConsentWithdrawContent;
    /**
     * Contents of the button used for submitting a consent when reauth is
     * required.
     * (-- api-linter: core::0140::prepositions=disabled --)
     *
     * @generated from protobuf field: consent.common.bff.api.v1.Button submit_with_reauth_button = 9;
     */
    submitWithReauthButton?: Button;
}
/**
 * A module containing a single content type to display to the user.
 *
 * @generated from protobuf message consent.common.bff.api.v1.ContentModule
 */
export interface ContentModule {
    /**
     * @generated from protobuf oneof: module
     */
    module: {
        oneofKind: "pdfModule";
        /**
         * A module that contains information about a PDF to be rendered.
         *
         * @generated from protobuf field: consent.common.bff.api.v1.PdfModule pdf_module = 1;
         */
        pdfModule: PdfModule;
    } | {
        oneofKind: "signatureBlockModule";
        /**
         * A module that contains information and labels for a single signature.
         *
         * @generated from protobuf field: consent.common.bff.api.v1.SignatureBlock signature_block_module = 2;
         */
        signatureBlockModule: SignatureBlock;
    } | {
        oneofKind: "htmlContentModule";
        /**
         * A module that contains information about a piece of generic HTML content.
         *
         * @generated from protobuf field: consent.common.bff.api.v1.HtmlContentModule html_content_module = 3;
         */
        htmlContentModule: HtmlContentModule;
    } | {
        oneofKind: "checkboxModule";
        /**
         * A module that contains checkbox content, currently only for an
         * "agreement".
         *
         * @generated from protobuf field: consent.common.bff.api.v1.CheckboxModule checkbox_module = 4;
         */
        checkboxModule: CheckboxModule;
    } | {
        oneofKind: "linkModule";
        /**
         * A module that contains links.
         *
         * @generated from protobuf field: consent.common.bff.api.v1.LinkModule link_module = 5;
         */
        linkModule: LinkModule;
    } | {
        oneofKind: "richtextModule";
        /**
         * A module that represents rich text content.
         *
         * @generated from protobuf field: consent.common.bff.api.v1.RichtextModule richtext_module = 6;
         */
        richtextModule: RichtextModule;
    } | {
        oneofKind: "highlightModule";
        /**
         * A module that represents a highlight box.
         *
         * @generated from protobuf field: consent.common.bff.api.v1.HighlightModule highlight_module = 7;
         */
        highlightModule: HighlightModule;
    } | {
        oneofKind: "sectionModule";
        /**
         * A module that represents a Section in a Consent.
         *
         * @generated from protobuf field: consent.common.bff.api.v1.SectionModule section_module = 8;
         */
        sectionModule: SectionModule;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Content relating to reviewing a signed consent.
 *
 * @generated from protobuf message consent.common.bff.api.v1.SignedConsentReviewContent
 */
export interface SignedConsentReviewContent {
    /**
     * Label preceding the signing date/time for a consent.
     *
     * @generated from protobuf field: string consent_signed_on_label = 1;
     */
    consentSignedOnLabel: string;
    /**
     * Label on button to open consent PDF in a new tab.
     *
     * @generated from protobuf field: string view_consent_pdf_button_label = 2;
     */
    viewConsentPdfButtonLabel: string;
    /**
     * Label preceding the signing date/time.
     *
     * @generated from protobuf field: string signed_on_label = 3;
     */
    signedOnLabel: string;
}
/**
 * Content relating to withdrawing a signed consent.
 *
 * @generated from protobuf message consent.common.bff.api.v1.SignedConsentWithdrawContent
 */
export interface SignedConsentWithdrawContent {
    /**
     * Content of the button used to withdraw a signed consent.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.Button withdraw_consent_button = 1;
     */
    withdrawConsentButton?: Button;
    /**
     * Contents of the dialog popup for continuing the consent withdrawal flow.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.DialogContent withdraw_dialog_content = 4;
     */
    withdrawDialogContent?: DialogContent;
    /**
     * Content of the toast message confirming consent has been withdrawn.
     *
     * @generated from protobuf field: string withdraw_confirmation_message = 5;
     */
    withdrawConfirmationMessage: string;
    /**
     * Label to precede date/time in the review header if consent withdrawn.
     * See https://screenshot.googleplex.com/4bpDx4H6NANZLcU.
     *
     * @generated from protobuf field: string consent_withdrawn_on_label = 6;
     */
    consentWithdrawnOnLabel: string;
    /**
     * Label preceding the withdrawn date/time.
     * See https://screenshot.googleplex.com/4btPHJkfAX7H8Fk.
     *
     * @generated from protobuf field: string withdrawn_on_label = 7;
     */
    withdrawnOnLabel: string;
}
/**
 * A module that contains information about links. Can contain multiple links.
 *
 * @generated from protobuf message consent.common.bff.api.v1.LinkModule
 */
export interface LinkModule {
    /**
     * list of links presented to the user.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.Link links = 1;
     */
    links: Link[];
}
/**
 * Details to render a link.
 *
 * @generated from protobuf message consent.common.bff.api.v1.Link
 */
export interface Link {
    /**
     * text rendering the link
     *
     * @generated from protobuf field: string text = 1;
     */
    text: string;
    /**
     * url associated with the link.
     * (-- api-linter: core::0140::uri=disabled --)
     *
     * @generated from protobuf field: string url = 2;
     */
    url: string;
}
/**
 * A module that contains information about a piece of generic HTML content.
 *
 * @generated from protobuf message consent.common.bff.api.v1.HtmlContentModule
 */
export interface HtmlContentModule {
    /**
     * Unsanitized HTML content that is dispalyed to the user.
     *
     * @generated from protobuf field: string html = 1;
     */
    html: string;
}
/**
 * A block intended to capture generic checkbox content.
 *
 * @generated from protobuf message consent.common.bff.api.v1.CheckboxModule
 */
export interface CheckboxModule {
    /**
     * One or more reasons presented to the user that can be accepted.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.SigningReason reasons = 1;
     */
    reasons: SigningReason[];
    /**
     * Label to denote a reason as required.
     *
     * @generated from protobuf field: string required_label = 2;
     */
    requiredLabel: string;
}
/**
 * A module that provides a reference to a PDF to be rendered to the user.
 *
 * @generated from protobuf message consent.common.bff.api.v1.PdfModule
 */
export interface PdfModule {
    /**
     * Public URL of the PDF.
     *
     * @deprecated
     * @generated from protobuf field: string document_url = 1 [deprecated = true];
     */
    documentUrl: string;
    /**
     * PDF document to render.
     *
     * @generated from protobuf field: bytes document = 2;
     */
    document: Uint8Array;
    /**
     * Hint to let touch screen users know they can use their fingers to zoom in
     * on the PDF.
     * (-- api-linter: core::0140::prepositions=disabled --)
     *
     * @generated from protobuf field: string pinch_to_zoom_hint = 3;
     */
    pinchToZoomHint: string;
}
/**
 * A module that represents rich text content.
 *
 * @generated from protobuf message consent.common.bff.api.v1.RichtextModule
 */
export interface RichtextModule {
    /**
     * Rich Text string that is displayed to the user.
     *
     * @generated from protobuf field: string rich_text = 1;
     */
    richText: string;
}
/**
 * A module that represents a highlight box. The content of highlight is a RichtextModule
 *
 * @generated from protobuf message consent.common.bff.api.v1.HighlightModule
 */
export interface HighlightModule {
    /**
     * Rich Text string that is displayed to the user.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.RichtextModule highlight_content = 1;
     */
    highlightContent?: RichtextModule;
}
/**
 * A module that represents a Section in a Consent. A SectionModule can contain other ContentModules as children
 *
 * @generated from protobuf message consent.common.bff.api.v1.SectionModule
 */
export interface SectionModule {
    /**
     * A section contains a title.
     *
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * A section contains a list of ContentModules.
     * Note: For the Modular Consent Use case, a section can never have another section as a child.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.ContentModule content_modules = 2;
     */
    contentModules: ContentModule[];
}
/**
 * Content of dialog popup.
 *
 * @generated from protobuf message consent.common.bff.api.v1.DialogContent
 */
export interface DialogContent {
    /**
     * Dialog content.
     *
     * @generated from protobuf field: string body_html = 1;
     */
    bodyHtml: string;
    /**
     * Label for button to confirm.
     *
     * @generated from protobuf field: string confirm_button_label = 2;
     */
    confirmButtonLabel: string;
    /**
     * Label for button to cancel.
     *
     * @generated from protobuf field: string cancel_button_label = 3;
     */
    cancelButtonLabel: string;
    /**
     * Title message.
     *
     * @generated from protobuf field: string title = 4;
     */
    title: string;
}
/**
 * A block intended to capture a single user's signature.
 *
 * @generated from protobuf message consent.common.bff.api.v1.SignatureBlock
 */
export interface SignatureBlock {
    /**
     * Textbox for the user's legal name.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.TextBox name_textbox = 2;
     */
    nameTextbox?: TextBox;
    /**
     * Label displaying the date of signature.
     *
     * @generated from protobuf field: string date_label = 3;
     */
    dateLabel: string;
    /**
     * Handwritten signature presented to the user.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.HandwrittenSignature handwritten_signature = 4;
     */
    handwrittenSignature?: HandwrittenSignature;
    /**
     * Toggle button to select draw to sign.
     * (-- api-linter: core::0140::prepositions=disabled --)
     *
     * @generated from protobuf field: consent.common.bff.api.v1.Button draw_to_sign_button = 5;
     */
    drawToSignButton?: Button;
    /**
     * Toggle button to select type to sign.
     * (-- api-linter: core::0140::prepositions=disabled --)
     *
     * @generated from protobuf field: consent.common.bff.api.v1.Button type_to_sign_button = 6;
     */
    typeToSignButton?: Button;
    /**
     * Contents of dialog popup for selecting type to sign.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.DialogContent reauth_required_content = 7;
     */
    reauthRequiredContent?: DialogContent;
}
/**
 * Contains content of a handwritten signature to be rendered.
 *
 * @generated from protobuf message consent.common.bff.api.v1.HandwrittenSignature
 */
export interface HandwrittenSignature {
    /**
     * The title to be shown for the handwritten signature.
     *
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * The content when providing a handwritten signature.
     *
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * Contents of the button used to clear.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.Button clear_button = 3;
     */
    clearButton?: Button;
    /**
     * Contents of signature pad, provided to screenreader.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.SignaturePadAccessibilityContent signature_pad_accessibility_content = 4;
     */
    signaturePadAccessibilityContent?: SignaturePadAccessibilityContent;
}
/**
 * Contains accessibility content of signature pad to be read by screenreader.
 *
 * @generated from protobuf message consent.common.bff.api.v1.SignaturePadAccessibilityContent
 */
export interface SignaturePadAccessibilityContent {
    /**
     * Label when signature pad is empty.
     *
     * @generated from protobuf field: string empty = 1;
     */
    empty: string;
    /**
     * Label when signature pad is filled.
     *
     * @generated from protobuf field: string filled = 2;
     */
    filled: string;
    /**
     * Label when signature pad is disabled.
     *
     * @generated from protobuf field: string disabled = 3;
     */
    disabled: string;
    /**
     * Signature pad description when draw to sign is selected.
     *
     * @generated from protobuf field: string draw_signature_pad = 4;
     */
    drawSignaturePad: string;
    /**
     * Hint if signature pad is empty and draw to sign is selected.
     *
     * @generated from protobuf field: string draw_signature_hint = 5;
     */
    drawSignatureHint: string;
    /**
     * Signature pad description when type to sign is selected.
     *
     * @generated from protobuf field: string type_signature_pad = 6;
     */
    typeSignaturePad: string;
    /**
     * Hint if signature pad is empty and type to sign is selected.
     *
     * @generated from protobuf field: string type_signature_hint = 7;
     */
    typeSignatureHint: string;
    /**
     * Hint for the button to clear the writing on the canvas.
     *
     * @generated from protobuf field: string clear_signature_button_hint = 8;
     */
    clearSignatureButtonHint: string;
    /**
     * Label for signature canvas.
     *
     * @generated from protobuf field: string signature_canvas_label = 9;
     */
    signatureCanvasLabel: string;
}
/**
 * Used to track signing reasons and whether or not they have been accepted.
 *
 * @generated from protobuf message consent.common.bff.api.v1.SigningReason
 */
export interface SigningReason {
    /**
     * Text of the signing reason.
     *
     * @generated from protobuf field: string reason_text = 1;
     */
    reasonText: string;
    /**
     * Unique identifier of the reason.
     * Populated upon insertion to DB via cloud run.
     *
     * @generated from protobuf field: string reason_id = 3;
     */
    reasonId: string;
    /**
     * Indicates whether the reason is required or optional.
     *
     * @generated from protobuf field: bool is_required = 4;
     */
    isRequired: boolean;
}
/**
 * Contents of a generic textbox with a label and pre-filled value.
 *
 * @generated from protobuf message consent.common.bff.api.v1.TextBox
 */
export interface TextBox {
    /**
     * Textbox label to display.
     *
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * Textbox contents to pre-fill.
     *
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * Contents of a generic button.
 *
 * @generated from protobuf message consent.common.bff.api.v1.Button
 */
export interface Button {
    /**
     * Label to display for the button.
     *
     * @generated from protobuf field: string label = 1;
     */
    label: string;
}
/**
 * A record of a consent belonging to a user.
 *
 * @generated from protobuf message consent.common.bff.api.v1.ConsentRecord
 */
export interface ConsentRecord {
    /**
     * Unique identifier of the consent record.
     *
     * @generated from protobuf field: string consent_record_id = 1;
     */
    consentRecordId: string;
    /**
     * Tenant grouping of consents (analogous to study in CSP).
     * Changing to Optional because this field will be deprecated;
     * organization compartment will be derived from the patient.
     * This value is found on field number 17, org_compartment_name.
     *
     * @generated from protobuf field: string program_id = 2;
     */
    programId: string;
    /**
     * Unique identifier for a consent (e.g. HIPAA, CBOR, etc.). Must correspond
     * to the content_id of a workflow in Consent Studio.
     * (-- api-linter: core::0140::uri=disabled --)
     *
     * @generated from protobuf field: string consent_id = 3;
     */
    consentId: string;
    /**
     * Unique identifier for the subject of the consent.
     *
     * @generated from protobuf field: string subject_id = 4;
     */
    subjectId: string;
    /**
     * Locale of consent to display to the user.
     *
     * @generated from protobuf field: string locale = 5;
     */
    locale: string;
    /**
     * The ISO8601 formatted user's time zone relative to UTC (±hh:mm).
     * Required. If unknown, calling services should default to +00:00.
     *
     * @generated from protobuf field: string time_zone = 9;
     */
    timeZone: string;
    /**
     * Identifies the revision of the consent that was signed. Must correspond to
     * a revision in Consent Studio.
     *
     * @generated from protobuf field: int64 consent_revision = 6;
     */
    consentRevision: bigint;
    /**
     * Url to redirect the client to once the consent is signed.
     * (-- api-linter: core::0140::uri=disabled --)
     *
     * @generated from protobuf field: string consent_signed_redirect_url = 7;
     */
    consentSignedRedirectUrl: string;
    /**
     * List of signatures given on the consent.
     * Note: Currently the service only supports a single signature but this will
     * likely change in the future.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.Signature signatures = 8;
     */
    signatures: Signature[];
    /**
     * Specify a list of asynchronous actions to be performed as part of the
     * consent record creation.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.SideEffect side_effects = 10;
     */
    sideEffects: SideEffect[];
    /**
     * Url to redirect the client to if the consent is declined.
     * (-- api-linter: core::0140::uri=disabled --)
     *
     * @generated from protobuf field: string consent_declined_redirect_url = 11;
     */
    consentDeclinedRedirectUrl: string;
    /**
     * The state of the consent record.
     * (-- api-linter: core::0216::state-field-output-only=disabled --)
     *
     * @generated from protobuf field: consent.common.bff.api.v1.State state = 12;
     */
    state: State;
    /**
     * Url to redirect the client to if the consent is deffered.
     * (-- api-linter: core::0140::uri=disabled --)
     *
     * @generated from protobuf field: string consent_deferred_redirect_url = 13;
     */
    consentDeferredRedirectUrl: string;
    /**
     * The date and time the consent was withdrawn, in UTC.
     * Set by the backend.
     * (-- api-linter: core::0142::time-field-names=disabled --)
     *
     * @generated from protobuf field: google.protobuf.Timestamp withdrawn_timestamp = 15;
     */
    withdrawnTimestamp?: Timestamp;
    /**
     * The date and time the consent was withdrawn, localized to the user’s locale
     * for display to the user
     * (https://screenshot.googleplex.com/6zamm56FZuvpYWJ). This timestamp will be
     * displayed in the timezone defined in ConsentRecord.time_zone. Set by the
     * backend.
     * (-- api-linter: core::0142::time-field-type=disabled --)
     *
     * @generated from protobuf field: string localized_withdrawn_timestamp = 16;
     */
    localizedWithdrawnTimestamp: string;
    /**
     * The organization that the consent record belongs to.
     * (-- api-linter: core::0122::name-suffix=disabled --)
     *
     * @generated from protobuf field: string org_compartment_name = 17;
     */
    orgCompartmentName: string;
}
/**
 * A user's signature record.
 *
 * @generated from protobuf message consent.common.bff.api.v1.Signature
 */
export interface Signature {
    /**
     * Legal name as provided by the user.
     * Must be set at signing time.
     * (-- api-linter: core::0122::name-suffix=disabled --)
     *
     * @generated from protobuf field: string legal_name = 1;
     */
    legalName: string;
    /**
     * The date and time the consent was signed, in UTC.
     * Set by the backend.
     * (-- api-linter: core::0142::time-field-names=disabled --)
     *
     * @generated from protobuf field: google.protobuf.Timestamp signing_timestamp = 4;
     */
    signingTimestamp?: Timestamp;
    /**
     * The date and time the consent was signed, localized to the user's locale,
     * for display to the user. This timestamp will be displayed in the timezone
     * defined in ConsentRecord.time_zone.
     * Set by the backend.
     * (-- api-linter: core::0142::time-field-type=disabled --)
     *
     * @generated from protobuf field: string localized_signing_timestamp = 2;
     */
    localizedSigningTimestamp: string;
    /**
     * PNG representation of the user's signature.
     * Must be set at signing time.
     *
     * @generated from protobuf field: bytes signature_image = 3;
     */
    signatureImage: Uint8Array;
    /**
     * All required signing reasons, and any checked optional reasons associated
     * with the signature. Set at signing time.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.SigningReason reasons = 5;
     */
    reasons: SigningReason[];
    /**
     * Identifier to use to verify the viewer/signee is the expected user.
     * For a participant, this will be the FHIR Person ID returned by CIAM.
     * In the future when we support multi-tenancy, this ID may be associated
     * with an Auth0 UserId, as determined by tenant settings.
     *
     * @generated from protobuf field: string user_id = 6;
     */
    userId: string;
}
/**
 * Metadata surrounding a consent.
 * Next ID: 11
 *
 * @generated from protobuf message consent.common.bff.api.v1.ConsentMetadata
 */
export interface ConsentMetadata {
    /**
     * An id represents a consent.
     *
     * @generated from protobuf field: string consent_id = 1;
     */
    consentId: string;
    /**
     * Revision of the consent.
     *
     * @generated from protobuf field: int64 consent_revision = 2;
     */
    consentRevision: bigint;
    /**
     * Internal name defined by CMS/VCMS user.
     * (-- api-linter: core::0122::name-suffix=disabled --)
     *
     * @generated from protobuf field: string internal_name = 3;
     */
    internalName: string;
    /**
     * The consent type. This value comes from VCMS
     * (e.g "regulated-consents", "agreement-consents", etc).
     *
     * @generated from protobuf field: string consent_type = 10;
     */
    consentType: string;
    /**
     * List of locale infos.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.LocaleMetadata locale_metadata = 4;
     */
    localeMetadata: LocaleMetadata[];
    /**
     * List of signature infos.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.SignatureMetadata signature_metadata = 5;
     */
    signatureMetadata: SignatureMetadata[];
    /**
     * Date the consent was IRB approved, in UTC.
     * Deprecated. Please use LocaleMetadata's KeyValueMetadata.
     *
     * @deprecated
     * @generated from protobuf field: google.protobuf.Timestamp irb_approval_date = 6 [deprecated = true];
     */
    irbApprovalDate?: Timestamp;
    /**
     * External id  of the site.
     * Deprecated. Please use KeyValueMetadata.
     *
     * @deprecated
     * @generated from protobuf field: string site_id = 7 [deprecated = true];
     */
    siteId: string;
    /**
     * Name of the principal investigator of the consent.
     * Deprecated. Please use KeyValueMetadata.
     *
     * @deprecated
     * @generated from protobuf field: string principal_investigator_name = 8 [deprecated = true];
     */
    principalInvestigatorName: string;
    /**
     * List of key-value infos. Possible pairs for a regulated consent include
     * - principialInvestigatorName, String. Represents the name of the PI.
     * - siteId, String. The id of the site.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.KeyValueMetadata key_value_metadata = 9;
     */
    keyValueMetadata: KeyValueMetadata[];
}
/**
 * Metadata for a signature
 *
 * @generated from protobuf message consent.common.bff.api.v1.SignatureMetadata
 */
export interface SignatureMetadata {
    /**
     * Actor required to sign the consent.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.SignatureActor signature_actor = 5;
     */
    signatureActor: SignatureActor;
}
/**
 * Metadata for the locale
 *
 * @generated from protobuf message consent.common.bff.api.v1.LocaleMetadata
 */
export interface LocaleMetadata {
    /**
     * Specific language code in BCP-47 format ("en-US", "es-ES", etc).
     *
     * @generated from protobuf field: string language_code = 1;
     */
    languageCode: string;
    /**
     * Localized title of the consent.
     *
     * @generated from protobuf field: string title = 3;
     */
    title: string;
    /**
     * List of key-value metadata info. Possible paris for a regulated consent
     * include
     * - irbApprovalDate, Date representing when the consent was IRB approved.
     *
     * @generated from protobuf field: repeated consent.common.bff.api.v1.KeyValueMetadata key_value_metadata = 2;
     */
    keyValueMetadata: KeyValueMetadata[];
}
/**
 * Metadata represented in a key-value format.
 *
 * @generated from protobuf message consent.common.bff.api.v1.KeyValueMetadata
 */
export interface KeyValueMetadata {
    /**
     * Key representing the metadata
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "stringValue";
        /**
         * String value
         *
         * @generated from protobuf field: string string_value = 2;
         */
        stringValue: string;
    } | {
        oneofKind: "dateValue";
        /**
         * Date value
         *
         * @generated from protobuf field: google.type.Date date_value = 3;
         */
        dateValue: Date;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Side effects are asynchronous actions that the service performs, typically in
 * response to a change in consent status/state. Note that there may be a delay
 * in processing these side effects and the Consent API should be used as the
 * source of truth for consent status/state rather than artifacts generated via
 * side effects.
 *
 * @generated from protobuf message consent.common.bff.api.v1.SideEffect
 */
export interface SideEffect {
    /**
     * @generated from protobuf oneof: side_effect_option
     */
    sideEffectOption: {
        oneofKind: "writeConsentToFhir";
        /**
         * Side effect that writes a signed consent record to a specific FHIR store.
         * Right now this is only triggered when a consent is signed. Later as we
         * support more consent statuses and side effect specifications, FHIR writes
         * could take place under other triggers.
         * (-- api-linter: core::0140::prepositions=disabled --)
         *
         * @generated from protobuf field: consent.common.bff.api.v1.WriteConsentToFhir write_consent_to_fhir = 1;
         */
        writeConsentToFhir: WriteConsentToFhir;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Defines a side effect that writes a consent record to FHIR store and its
 * required params
 *
 * @generated from protobuf message consent.common.bff.api.v1.WriteConsentToFhir
 */
export interface WriteConsentToFhir {
    /**
     * Path to locate to the FHIR store
     *
     * @generated from protobuf field: string fhir_store_path = 1;
     */
    fhirStorePath: string;
    /**
     * Id to the subject resource to associate with
     *
     * @generated from protobuf field: string fhir_store_subject_resource_id = 2;
     */
    fhirStoreSubjectResourceId: string;
}
/**
 * Proto wrapper for ConsentRecord and ConsentContent.
 *
 * @generated from protobuf message consent.common.bff.api.v1.ClientConsentContent
 */
export interface ClientConsentContent {
    /**
     * The consent record associated with the requested record ID.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.ConsentRecord consent_record = 1;
     */
    consentRecord?: ConsentRecord;
    /**
     * The consent content associated with the requested record ID.
     *
     * @generated from protobuf field: consent.common.bff.api.v1.ConsentContent consent_content = 2;
     */
    consentContent?: ConsentContent;
}
/**
 * Localized error content.
 *
 * @generated from protobuf message consent.common.bff.api.v1.ErrorContent
 */
export interface ErrorContent {
    /**
     * Localized default error message.
     *
     * @generated from protobuf field: string default_error_message = 1;
     */
    defaultErrorMessage: string;
    /**
     * Localized back button aria label.
     *
     * @generated from protobuf field: string back_button_label = 2;
     */
    backButtonLabel: string;
    /**
     * Localized back button tooltip.
     *
     * @generated from protobuf field: string back_button_tooltip = 3;
     */
    backButtonTooltip: string;
}
/**
 * Actors that can sign a consent.
 *
 * @generated from protobuf enum consent.common.bff.api.v1.SignatureActor
 */
export enum SignatureActor {
    /**
     * Unspecified.
     *
     * @generated from protobuf enum value: SIGNATURE_ACTOR_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * Signature required by user.
     *
     * @generated from protobuf enum value: SIGNATURE_ACTOR_USER = 1;
     */
    USER = 1,
    /**
     * Signature required by admin.
     *
     * @generated from protobuf enum value: SIGNATURE_ACTOR_ADMIN = 2;
     */
    ADMIN = 2
}
/**
 * Defines the state of a consent record.
 *
 * @generated from protobuf enum consent.common.bff.api.v1.State
 */
export enum State {
    /**
     * Unspecified.
     *
     * @generated from protobuf enum value: STATE_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * Consent has been signed.
     *
     * @generated from protobuf enum value: STATE_SIGNED = 1;
     */
    SIGNED = 1,
    /**
     * Consent has been declined.
     *
     * @generated from protobuf enum value: STATE_DECLINED = 2;
     */
    DECLINED = 2,
    /**
     * Consent has been viewed, but no action has been taken after viewing.
     *
     * @generated from protobuf enum value: STATE_VIEWED = 3;
     */
    VIEWED = 3,
    /**
     * Consent has been withdrawn.
     *
     * @generated from protobuf enum value: STATE_WITHDRAWN = 4;
     */
    WITHDRAWN = 4,
    /**
     * For consents with typed signatures. Consent submission is pending reauth.
     *
     * @generated from protobuf enum value: STATE_PENDING_REAUTH = 5;
     */
    PENDING_REAUTH = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class ConsentContent$Type extends MessageType<ConsentContent> {
    constructor() {
        super("consent.common.bff.api.v1.ConsentContent", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "content_modules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ContentModule, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "submit_button", kind: "message", T: () => Button, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 4, name: "decline_button", kind: "message", T: () => Button, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 5, name: "disagree_dialog_content", kind: "message", T: () => DialogContent, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 7, name: "signed_consent_review_content", kind: "message", T: () => SignedConsentReviewContent, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 8, name: "signed_consent_withdraw_content", kind: "message", T: () => SignedConsentWithdrawContent, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 9, name: "submit_with_reauth_button", kind: "message", T: () => Button, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<ConsentContent>): ConsentContent {
        const message = { title: "", contentModules: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentContent): ConsentContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* repeated consent.common.bff.api.v1.ContentModule content_modules */ 2:
                    message.contentModules.push(ContentModule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* consent.common.bff.api.v1.Button submit_button */ 3:
                    message.submitButton = Button.internalBinaryRead(reader, reader.uint32(), options, message.submitButton);
                    break;
                case /* consent.common.bff.api.v1.Button decline_button */ 4:
                    message.declineButton = Button.internalBinaryRead(reader, reader.uint32(), options, message.declineButton);
                    break;
                case /* consent.common.bff.api.v1.DialogContent disagree_dialog_content */ 5:
                    message.disagreeDialogContent = DialogContent.internalBinaryRead(reader, reader.uint32(), options, message.disagreeDialogContent);
                    break;
                case /* consent.common.bff.api.v1.SignedConsentReviewContent signed_consent_review_content */ 7:
                    message.signedConsentReviewContent = SignedConsentReviewContent.internalBinaryRead(reader, reader.uint32(), options, message.signedConsentReviewContent);
                    break;
                case /* consent.common.bff.api.v1.SignedConsentWithdrawContent signed_consent_withdraw_content */ 8:
                    message.signedConsentWithdrawContent = SignedConsentWithdrawContent.internalBinaryRead(reader, reader.uint32(), options, message.signedConsentWithdrawContent);
                    break;
                case /* consent.common.bff.api.v1.Button submit_with_reauth_button */ 9:
                    message.submitWithReauthButton = Button.internalBinaryRead(reader, reader.uint32(), options, message.submitWithReauthButton);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsentContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* repeated consent.common.bff.api.v1.ContentModule content_modules = 2; */
        for (let i = 0; i < message.contentModules.length; i++)
            ContentModule.internalBinaryWrite(message.contentModules[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.Button submit_button = 3; */
        if (message.submitButton)
            Button.internalBinaryWrite(message.submitButton, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.Button decline_button = 4; */
        if (message.declineButton)
            Button.internalBinaryWrite(message.declineButton, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.DialogContent disagree_dialog_content = 5; */
        if (message.disagreeDialogContent)
            DialogContent.internalBinaryWrite(message.disagreeDialogContent, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.SignedConsentReviewContent signed_consent_review_content = 7; */
        if (message.signedConsentReviewContent)
            SignedConsentReviewContent.internalBinaryWrite(message.signedConsentReviewContent, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.SignedConsentWithdrawContent signed_consent_withdraw_content = 8; */
        if (message.signedConsentWithdrawContent)
            SignedConsentWithdrawContent.internalBinaryWrite(message.signedConsentWithdrawContent, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.Button submit_with_reauth_button = 9; */
        if (message.submitWithReauthButton)
            Button.internalBinaryWrite(message.submitWithReauthButton, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.ConsentContent
 */
export const ConsentContent = new ConsentContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContentModule$Type extends MessageType<ContentModule> {
    constructor() {
        super("consent.common.bff.api.v1.ContentModule", [
            { no: 1, name: "pdf_module", kind: "message", oneof: "module", T: () => PdfModule },
            { no: 2, name: "signature_block_module", kind: "message", oneof: "module", T: () => SignatureBlock },
            { no: 3, name: "html_content_module", kind: "message", oneof: "module", T: () => HtmlContentModule },
            { no: 4, name: "checkbox_module", kind: "message", oneof: "module", T: () => CheckboxModule },
            { no: 5, name: "link_module", kind: "message", oneof: "module", T: () => LinkModule },
            { no: 6, name: "richtext_module", kind: "message", oneof: "module", T: () => RichtextModule },
            { no: 7, name: "highlight_module", kind: "message", oneof: "module", T: () => HighlightModule },
            { no: 8, name: "section_module", kind: "message", oneof: "module", T: () => SectionModule }
        ]);
    }
    create(value?: PartialMessage<ContentModule>): ContentModule {
        const message = { module: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContentModule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContentModule): ContentModule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* consent.common.bff.api.v1.PdfModule pdf_module */ 1:
                    message.module = {
                        oneofKind: "pdfModule",
                        pdfModule: PdfModule.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).pdfModule)
                    };
                    break;
                case /* consent.common.bff.api.v1.SignatureBlock signature_block_module */ 2:
                    message.module = {
                        oneofKind: "signatureBlockModule",
                        signatureBlockModule: SignatureBlock.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).signatureBlockModule)
                    };
                    break;
                case /* consent.common.bff.api.v1.HtmlContentModule html_content_module */ 3:
                    message.module = {
                        oneofKind: "htmlContentModule",
                        htmlContentModule: HtmlContentModule.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).htmlContentModule)
                    };
                    break;
                case /* consent.common.bff.api.v1.CheckboxModule checkbox_module */ 4:
                    message.module = {
                        oneofKind: "checkboxModule",
                        checkboxModule: CheckboxModule.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).checkboxModule)
                    };
                    break;
                case /* consent.common.bff.api.v1.LinkModule link_module */ 5:
                    message.module = {
                        oneofKind: "linkModule",
                        linkModule: LinkModule.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).linkModule)
                    };
                    break;
                case /* consent.common.bff.api.v1.RichtextModule richtext_module */ 6:
                    message.module = {
                        oneofKind: "richtextModule",
                        richtextModule: RichtextModule.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).richtextModule)
                    };
                    break;
                case /* consent.common.bff.api.v1.HighlightModule highlight_module */ 7:
                    message.module = {
                        oneofKind: "highlightModule",
                        highlightModule: HighlightModule.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).highlightModule)
                    };
                    break;
                case /* consent.common.bff.api.v1.SectionModule section_module */ 8:
                    message.module = {
                        oneofKind: "sectionModule",
                        sectionModule: SectionModule.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).sectionModule)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContentModule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* consent.common.bff.api.v1.PdfModule pdf_module = 1; */
        if (message.module.oneofKind === "pdfModule")
            PdfModule.internalBinaryWrite(message.module.pdfModule, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.SignatureBlock signature_block_module = 2; */
        if (message.module.oneofKind === "signatureBlockModule")
            SignatureBlock.internalBinaryWrite(message.module.signatureBlockModule, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.HtmlContentModule html_content_module = 3; */
        if (message.module.oneofKind === "htmlContentModule")
            HtmlContentModule.internalBinaryWrite(message.module.htmlContentModule, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.CheckboxModule checkbox_module = 4; */
        if (message.module.oneofKind === "checkboxModule")
            CheckboxModule.internalBinaryWrite(message.module.checkboxModule, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.LinkModule link_module = 5; */
        if (message.module.oneofKind === "linkModule")
            LinkModule.internalBinaryWrite(message.module.linkModule, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.RichtextModule richtext_module = 6; */
        if (message.module.oneofKind === "richtextModule")
            RichtextModule.internalBinaryWrite(message.module.richtextModule, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.HighlightModule highlight_module = 7; */
        if (message.module.oneofKind === "highlightModule")
            HighlightModule.internalBinaryWrite(message.module.highlightModule, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.SectionModule section_module = 8; */
        if (message.module.oneofKind === "sectionModule")
            SectionModule.internalBinaryWrite(message.module.sectionModule, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.ContentModule
 */
export const ContentModule = new ContentModule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignedConsentReviewContent$Type extends MessageType<SignedConsentReviewContent> {
    constructor() {
        super("consent.common.bff.api.v1.SignedConsentReviewContent", [
            { no: 1, name: "consent_signed_on_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "view_consent_pdf_button_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "signed_on_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<SignedConsentReviewContent>): SignedConsentReviewContent {
        const message = { consentSignedOnLabel: "", viewConsentPdfButtonLabel: "", signedOnLabel: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SignedConsentReviewContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignedConsentReviewContent): SignedConsentReviewContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_signed_on_label */ 1:
                    message.consentSignedOnLabel = reader.string();
                    break;
                case /* string view_consent_pdf_button_label */ 2:
                    message.viewConsentPdfButtonLabel = reader.string();
                    break;
                case /* string signed_on_label */ 3:
                    message.signedOnLabel = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignedConsentReviewContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_signed_on_label = 1; */
        if (message.consentSignedOnLabel !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentSignedOnLabel);
        /* string view_consent_pdf_button_label = 2; */
        if (message.viewConsentPdfButtonLabel !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.viewConsentPdfButtonLabel);
        /* string signed_on_label = 3; */
        if (message.signedOnLabel !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.signedOnLabel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.SignedConsentReviewContent
 */
export const SignedConsentReviewContent = new SignedConsentReviewContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignedConsentWithdrawContent$Type extends MessageType<SignedConsentWithdrawContent> {
    constructor() {
        super("consent.common.bff.api.v1.SignedConsentWithdrawContent", [
            { no: 1, name: "withdraw_consent_button", kind: "message", T: () => Button, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 4, name: "withdraw_dialog_content", kind: "message", T: () => DialogContent, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 5, name: "withdraw_confirmation_message", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 6, name: "consent_withdrawn_on_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 7, name: "withdrawn_on_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<SignedConsentWithdrawContent>): SignedConsentWithdrawContent {
        const message = { withdrawConfirmationMessage: "", consentWithdrawnOnLabel: "", withdrawnOnLabel: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SignedConsentWithdrawContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignedConsentWithdrawContent): SignedConsentWithdrawContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* consent.common.bff.api.v1.Button withdraw_consent_button */ 1:
                    message.withdrawConsentButton = Button.internalBinaryRead(reader, reader.uint32(), options, message.withdrawConsentButton);
                    break;
                case /* consent.common.bff.api.v1.DialogContent withdraw_dialog_content */ 4:
                    message.withdrawDialogContent = DialogContent.internalBinaryRead(reader, reader.uint32(), options, message.withdrawDialogContent);
                    break;
                case /* string withdraw_confirmation_message */ 5:
                    message.withdrawConfirmationMessage = reader.string();
                    break;
                case /* string consent_withdrawn_on_label */ 6:
                    message.consentWithdrawnOnLabel = reader.string();
                    break;
                case /* string withdrawn_on_label */ 7:
                    message.withdrawnOnLabel = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignedConsentWithdrawContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* consent.common.bff.api.v1.Button withdraw_consent_button = 1; */
        if (message.withdrawConsentButton)
            Button.internalBinaryWrite(message.withdrawConsentButton, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.DialogContent withdraw_dialog_content = 4; */
        if (message.withdrawDialogContent)
            DialogContent.internalBinaryWrite(message.withdrawDialogContent, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string withdraw_confirmation_message = 5; */
        if (message.withdrawConfirmationMessage !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.withdrawConfirmationMessage);
        /* string consent_withdrawn_on_label = 6; */
        if (message.consentWithdrawnOnLabel !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.consentWithdrawnOnLabel);
        /* string withdrawn_on_label = 7; */
        if (message.withdrawnOnLabel !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.withdrawnOnLabel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.SignedConsentWithdrawContent
 */
export const SignedConsentWithdrawContent = new SignedConsentWithdrawContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkModule$Type extends MessageType<LinkModule> {
    constructor() {
        super("consent.common.bff.api.v1.LinkModule", [
            { no: 1, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Link, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<LinkModule>): LinkModule {
        const message = { links: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LinkModule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LinkModule): LinkModule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated consent.common.bff.api.v1.Link links */ 1:
                    message.links.push(Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LinkModule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated consent.common.bff.api.v1.Link links = 1; */
        for (let i = 0; i < message.links.length; i++)
            Link.internalBinaryWrite(message.links[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.LinkModule
 */
export const LinkModule = new LinkModule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Link$Type extends MessageType<Link> {
    constructor() {
        super("consent.common.bff.api.v1.Link", [
            { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<Link>): Link {
        const message = { text: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Link>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Link): Link {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string text */ 1:
                    message.text = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Link, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string text = 1; */
        if (message.text !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.text);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.Link
 */
export const Link = new Link$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HtmlContentModule$Type extends MessageType<HtmlContentModule> {
    constructor() {
        super("consent.common.bff.api.v1.HtmlContentModule", [
            { no: 1, name: "html", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<HtmlContentModule>): HtmlContentModule {
        const message = { html: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HtmlContentModule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HtmlContentModule): HtmlContentModule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string html */ 1:
                    message.html = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HtmlContentModule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string html = 1; */
        if (message.html !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.html);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.HtmlContentModule
 */
export const HtmlContentModule = new HtmlContentModule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckboxModule$Type extends MessageType<CheckboxModule> {
    constructor() {
        super("consent.common.bff.api.v1.CheckboxModule", [
            { no: 1, name: "reasons", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SigningReason, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "required_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<CheckboxModule>): CheckboxModule {
        const message = { reasons: [], requiredLabel: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckboxModule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckboxModule): CheckboxModule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated consent.common.bff.api.v1.SigningReason reasons */ 1:
                    message.reasons.push(SigningReason.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string required_label */ 2:
                    message.requiredLabel = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckboxModule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated consent.common.bff.api.v1.SigningReason reasons = 1; */
        for (let i = 0; i < message.reasons.length; i++)
            SigningReason.internalBinaryWrite(message.reasons[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string required_label = 2; */
        if (message.requiredLabel !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.requiredLabel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.CheckboxModule
 */
export const CheckboxModule = new CheckboxModule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PdfModule$Type extends MessageType<PdfModule> {
    constructor() {
        super("consent.common.bff.api.v1.PdfModule", [
            { no: 1, name: "document_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "document", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "pinch_to_zoom_hint", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<PdfModule>): PdfModule {
        const message = { documentUrl: "", document: new Uint8Array(0), pinchToZoomHint: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PdfModule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PdfModule): PdfModule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string document_url = 1 [deprecated = true];*/ 1:
                    message.documentUrl = reader.string();
                    break;
                case /* bytes document */ 2:
                    message.document = reader.bytes();
                    break;
                case /* string pinch_to_zoom_hint */ 3:
                    message.pinchToZoomHint = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PdfModule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string document_url = 1 [deprecated = true]; */
        if (message.documentUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.documentUrl);
        /* bytes document = 2; */
        if (message.document.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.document);
        /* string pinch_to_zoom_hint = 3; */
        if (message.pinchToZoomHint !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pinchToZoomHint);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.PdfModule
 */
export const PdfModule = new PdfModule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RichtextModule$Type extends MessageType<RichtextModule> {
    constructor() {
        super("consent.common.bff.api.v1.RichtextModule", [
            { no: 1, name: "rich_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RichtextModule>): RichtextModule {
        const message = { richText: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RichtextModule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RichtextModule): RichtextModule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string rich_text */ 1:
                    message.richText = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RichtextModule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string rich_text = 1; */
        if (message.richText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.richText);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.RichtextModule
 */
export const RichtextModule = new RichtextModule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HighlightModule$Type extends MessageType<HighlightModule> {
    constructor() {
        super("consent.common.bff.api.v1.HighlightModule", [
            { no: 1, name: "highlight_content", kind: "message", T: () => RichtextModule, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<HighlightModule>): HighlightModule {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HighlightModule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HighlightModule): HighlightModule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* consent.common.bff.api.v1.RichtextModule highlight_content */ 1:
                    message.highlightContent = RichtextModule.internalBinaryRead(reader, reader.uint32(), options, message.highlightContent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HighlightModule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* consent.common.bff.api.v1.RichtextModule highlight_content = 1; */
        if (message.highlightContent)
            RichtextModule.internalBinaryWrite(message.highlightContent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.HighlightModule
 */
export const HighlightModule = new HighlightModule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SectionModule$Type extends MessageType<SectionModule> {
    constructor() {
        super("consent.common.bff.api.v1.SectionModule", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "content_modules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ContentModule, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<SectionModule>): SectionModule {
        const message = { title: "", contentModules: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SectionModule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SectionModule): SectionModule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* repeated consent.common.bff.api.v1.ContentModule content_modules */ 2:
                    message.contentModules.push(ContentModule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SectionModule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* repeated consent.common.bff.api.v1.ContentModule content_modules = 2; */
        for (let i = 0; i < message.contentModules.length; i++)
            ContentModule.internalBinaryWrite(message.contentModules[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.SectionModule
 */
export const SectionModule = new SectionModule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DialogContent$Type extends MessageType<DialogContent> {
    constructor() {
        super("consent.common.bff.api.v1.DialogContent", [
            { no: 1, name: "body_html", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "confirm_button_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "cancel_button_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<DialogContent>): DialogContent {
        const message = { bodyHtml: "", confirmButtonLabel: "", cancelButtonLabel: "", title: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DialogContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DialogContent): DialogContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string body_html */ 1:
                    message.bodyHtml = reader.string();
                    break;
                case /* string confirm_button_label */ 2:
                    message.confirmButtonLabel = reader.string();
                    break;
                case /* string cancel_button_label */ 3:
                    message.cancelButtonLabel = reader.string();
                    break;
                case /* string title */ 4:
                    message.title = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DialogContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string body_html = 1; */
        if (message.bodyHtml !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.bodyHtml);
        /* string confirm_button_label = 2; */
        if (message.confirmButtonLabel !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.confirmButtonLabel);
        /* string cancel_button_label = 3; */
        if (message.cancelButtonLabel !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.cancelButtonLabel);
        /* string title = 4; */
        if (message.title !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.title);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.DialogContent
 */
export const DialogContent = new DialogContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignatureBlock$Type extends MessageType<SignatureBlock> {
    constructor() {
        super("consent.common.bff.api.v1.SignatureBlock", [
            { no: 2, name: "name_textbox", kind: "message", T: () => TextBox, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "date_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 4, name: "handwritten_signature", kind: "message", T: () => HandwrittenSignature, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 5, name: "draw_to_sign_button", kind: "message", T: () => Button, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 6, name: "type_to_sign_button", kind: "message", T: () => Button, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 7, name: "reauth_required_content", kind: "message", T: () => DialogContent, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<SignatureBlock>): SignatureBlock {
        const message = { dateLabel: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SignatureBlock>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignatureBlock): SignatureBlock {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* consent.common.bff.api.v1.TextBox name_textbox */ 2:
                    message.nameTextbox = TextBox.internalBinaryRead(reader, reader.uint32(), options, message.nameTextbox);
                    break;
                case /* string date_label */ 3:
                    message.dateLabel = reader.string();
                    break;
                case /* consent.common.bff.api.v1.HandwrittenSignature handwritten_signature */ 4:
                    message.handwrittenSignature = HandwrittenSignature.internalBinaryRead(reader, reader.uint32(), options, message.handwrittenSignature);
                    break;
                case /* consent.common.bff.api.v1.Button draw_to_sign_button */ 5:
                    message.drawToSignButton = Button.internalBinaryRead(reader, reader.uint32(), options, message.drawToSignButton);
                    break;
                case /* consent.common.bff.api.v1.Button type_to_sign_button */ 6:
                    message.typeToSignButton = Button.internalBinaryRead(reader, reader.uint32(), options, message.typeToSignButton);
                    break;
                case /* consent.common.bff.api.v1.DialogContent reauth_required_content */ 7:
                    message.reauthRequiredContent = DialogContent.internalBinaryRead(reader, reader.uint32(), options, message.reauthRequiredContent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignatureBlock, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* consent.common.bff.api.v1.TextBox name_textbox = 2; */
        if (message.nameTextbox)
            TextBox.internalBinaryWrite(message.nameTextbox, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string date_label = 3; */
        if (message.dateLabel !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dateLabel);
        /* consent.common.bff.api.v1.HandwrittenSignature handwritten_signature = 4; */
        if (message.handwrittenSignature)
            HandwrittenSignature.internalBinaryWrite(message.handwrittenSignature, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.Button draw_to_sign_button = 5; */
        if (message.drawToSignButton)
            Button.internalBinaryWrite(message.drawToSignButton, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.Button type_to_sign_button = 6; */
        if (message.typeToSignButton)
            Button.internalBinaryWrite(message.typeToSignButton, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.DialogContent reauth_required_content = 7; */
        if (message.reauthRequiredContent)
            DialogContent.internalBinaryWrite(message.reauthRequiredContent, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.SignatureBlock
 */
export const SignatureBlock = new SignatureBlock$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandwrittenSignature$Type extends MessageType<HandwrittenSignature> {
    constructor() {
        super("consent.common.bff.api.v1.HandwrittenSignature", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "clear_button", kind: "message", T: () => Button, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 4, name: "signature_pad_accessibility_content", kind: "message", T: () => SignaturePadAccessibilityContent, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<HandwrittenSignature>): HandwrittenSignature {
        const message = { title: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HandwrittenSignature>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HandwrittenSignature): HandwrittenSignature {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* consent.common.bff.api.v1.Button clear_button */ 3:
                    message.clearButton = Button.internalBinaryRead(reader, reader.uint32(), options, message.clearButton);
                    break;
                case /* consent.common.bff.api.v1.SignaturePadAccessibilityContent signature_pad_accessibility_content */ 4:
                    message.signaturePadAccessibilityContent = SignaturePadAccessibilityContent.internalBinaryRead(reader, reader.uint32(), options, message.signaturePadAccessibilityContent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HandwrittenSignature, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* consent.common.bff.api.v1.Button clear_button = 3; */
        if (message.clearButton)
            Button.internalBinaryWrite(message.clearButton, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.SignaturePadAccessibilityContent signature_pad_accessibility_content = 4; */
        if (message.signaturePadAccessibilityContent)
            SignaturePadAccessibilityContent.internalBinaryWrite(message.signaturePadAccessibilityContent, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.HandwrittenSignature
 */
export const HandwrittenSignature = new HandwrittenSignature$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignaturePadAccessibilityContent$Type extends MessageType<SignaturePadAccessibilityContent> {
    constructor() {
        super("consent.common.bff.api.v1.SignaturePadAccessibilityContent", [
            { no: 1, name: "empty", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "filled", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "disabled", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 4, name: "draw_signature_pad", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 5, name: "draw_signature_hint", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 6, name: "type_signature_pad", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 7, name: "type_signature_hint", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 8, name: "clear_signature_button_hint", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 9, name: "signature_canvas_label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<SignaturePadAccessibilityContent>): SignaturePadAccessibilityContent {
        const message = { empty: "", filled: "", disabled: "", drawSignaturePad: "", drawSignatureHint: "", typeSignaturePad: "", typeSignatureHint: "", clearSignatureButtonHint: "", signatureCanvasLabel: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SignaturePadAccessibilityContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignaturePadAccessibilityContent): SignaturePadAccessibilityContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string empty */ 1:
                    message.empty = reader.string();
                    break;
                case /* string filled */ 2:
                    message.filled = reader.string();
                    break;
                case /* string disabled */ 3:
                    message.disabled = reader.string();
                    break;
                case /* string draw_signature_pad */ 4:
                    message.drawSignaturePad = reader.string();
                    break;
                case /* string draw_signature_hint */ 5:
                    message.drawSignatureHint = reader.string();
                    break;
                case /* string type_signature_pad */ 6:
                    message.typeSignaturePad = reader.string();
                    break;
                case /* string type_signature_hint */ 7:
                    message.typeSignatureHint = reader.string();
                    break;
                case /* string clear_signature_button_hint */ 8:
                    message.clearSignatureButtonHint = reader.string();
                    break;
                case /* string signature_canvas_label */ 9:
                    message.signatureCanvasLabel = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignaturePadAccessibilityContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string empty = 1; */
        if (message.empty !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.empty);
        /* string filled = 2; */
        if (message.filled !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.filled);
        /* string disabled = 3; */
        if (message.disabled !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.disabled);
        /* string draw_signature_pad = 4; */
        if (message.drawSignaturePad !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.drawSignaturePad);
        /* string draw_signature_hint = 5; */
        if (message.drawSignatureHint !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.drawSignatureHint);
        /* string type_signature_pad = 6; */
        if (message.typeSignaturePad !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.typeSignaturePad);
        /* string type_signature_hint = 7; */
        if (message.typeSignatureHint !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.typeSignatureHint);
        /* string clear_signature_button_hint = 8; */
        if (message.clearSignatureButtonHint !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.clearSignatureButtonHint);
        /* string signature_canvas_label = 9; */
        if (message.signatureCanvasLabel !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.signatureCanvasLabel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.SignaturePadAccessibilityContent
 */
export const SignaturePadAccessibilityContent = new SignaturePadAccessibilityContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SigningReason$Type extends MessageType<SigningReason> {
    constructor() {
        super("consent.common.bff.api.v1.SigningReason", [
            { no: 1, name: "reason_text", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 3, name: "reason_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 4, name: "is_required", kind: "scalar", T: 8 /*ScalarType.BOOL*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<SigningReason>): SigningReason {
        const message = { reasonText: "", reasonId: "", isRequired: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SigningReason>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SigningReason): SigningReason {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string reason_text */ 1:
                    message.reasonText = reader.string();
                    break;
                case /* string reason_id */ 3:
                    message.reasonId = reader.string();
                    break;
                case /* bool is_required */ 4:
                    message.isRequired = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SigningReason, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string reason_text = 1; */
        if (message.reasonText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.reasonText);
        /* string reason_id = 3; */
        if (message.reasonId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.reasonId);
        /* bool is_required = 4; */
        if (message.isRequired !== false)
            writer.tag(4, WireType.Varint).bool(message.isRequired);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.SigningReason
 */
export const SigningReason = new SigningReason$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextBox$Type extends MessageType<TextBox> {
    constructor() {
        super("consent.common.bff.api.v1.TextBox", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<TextBox>): TextBox {
        const message = { label: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TextBox>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TextBox): TextBox {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TextBox, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.TextBox
 */
export const TextBox = new TextBox$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Button$Type extends MessageType<Button> {
    constructor() {
        super("consent.common.bff.api.v1.Button", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<Button>): Button {
        const message = { label: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Button>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Button): Button {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Button, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.Button
 */
export const Button = new Button$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentRecord$Type extends MessageType<ConsentRecord> {
    constructor() {
        super("consent.common.bff.api.v1.ConsentRecord", [
            { no: 1, name: "consent_record_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "program_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 3, name: "consent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "validate.rules": { string: { minLen: "1" } } } },
            { no: 4, name: "subject_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "validate.rules": { string: { minLen: "1" } } } },
            { no: 5, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"], "validate.rules": { string: { minLen: "1" } } } },
            { no: 9, name: "time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"], "validate.rules": { string: { pattern: "^[+-](?:2[0-3]|[01][0-9]):[0-5][0-9]$" } } } },
            { no: 6, name: "consent_revision", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/, options: { "google.api.field_behavior": ["REQUIRED"], "validate.rules": { int64: { gte: "1" } } } },
            { no: 7, name: "consent_signed_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"], "validate.rules": { string: { uri: true, ignoreEmpty: true } } } },
            { no: 8, name: "signatures", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Signature, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 10, name: "side_effects", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SideEffect, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 11, name: "consent_declined_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"], "validate.rules": { string: { uri: true, ignoreEmpty: true } } } },
            { no: 12, name: "state", kind: "enum", T: () => ["consent.common.bff.api.v1.State", State, "STATE_"], options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 13, name: "consent_deferred_redirect_url", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"], "validate.rules": { string: { uri: true, ignoreEmpty: true } } } },
            { no: 15, name: "withdrawn_timestamp", kind: "message", T: () => Timestamp, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 16, name: "localized_withdrawn_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 17, name: "org_compartment_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<ConsentRecord>): ConsentRecord {
        const message = { consentRecordId: "", programId: "", consentId: "", subjectId: "", locale: "", timeZone: "", consentRevision: 0n, consentSignedRedirectUrl: "", signatures: [], sideEffects: [], consentDeclinedRedirectUrl: "", state: 0, consentDeferredRedirectUrl: "", localizedWithdrawnTimestamp: "", orgCompartmentName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentRecord): ConsentRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_record_id */ 1:
                    message.consentRecordId = reader.string();
                    break;
                case /* string program_id */ 2:
                    message.programId = reader.string();
                    break;
                case /* string consent_id */ 3:
                    message.consentId = reader.string();
                    break;
                case /* string subject_id */ 4:
                    message.subjectId = reader.string();
                    break;
                case /* string locale */ 5:
                    message.locale = reader.string();
                    break;
                case /* string time_zone */ 9:
                    message.timeZone = reader.string();
                    break;
                case /* int64 consent_revision */ 6:
                    message.consentRevision = reader.int64().toBigInt();
                    break;
                case /* string consent_signed_redirect_url */ 7:
                    message.consentSignedRedirectUrl = reader.string();
                    break;
                case /* repeated consent.common.bff.api.v1.Signature signatures */ 8:
                    message.signatures.push(Signature.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated consent.common.bff.api.v1.SideEffect side_effects */ 10:
                    message.sideEffects.push(SideEffect.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string consent_declined_redirect_url */ 11:
                    message.consentDeclinedRedirectUrl = reader.string();
                    break;
                case /* consent.common.bff.api.v1.State state */ 12:
                    message.state = reader.int32();
                    break;
                case /* string consent_deferred_redirect_url */ 13:
                    message.consentDeferredRedirectUrl = reader.string();
                    break;
                case /* google.protobuf.Timestamp withdrawn_timestamp */ 15:
                    message.withdrawnTimestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.withdrawnTimestamp);
                    break;
                case /* string localized_withdrawn_timestamp */ 16:
                    message.localizedWithdrawnTimestamp = reader.string();
                    break;
                case /* string org_compartment_name */ 17:
                    message.orgCompartmentName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsentRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_record_id = 1; */
        if (message.consentRecordId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentRecordId);
        /* string program_id = 2; */
        if (message.programId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.programId);
        /* string consent_id = 3; */
        if (message.consentId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.consentId);
        /* string subject_id = 4; */
        if (message.subjectId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.subjectId);
        /* string locale = 5; */
        if (message.locale !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.locale);
        /* string time_zone = 9; */
        if (message.timeZone !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.timeZone);
        /* int64 consent_revision = 6; */
        if (message.consentRevision !== 0n)
            writer.tag(6, WireType.Varint).int64(message.consentRevision);
        /* string consent_signed_redirect_url = 7; */
        if (message.consentSignedRedirectUrl !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.consentSignedRedirectUrl);
        /* repeated consent.common.bff.api.v1.Signature signatures = 8; */
        for (let i = 0; i < message.signatures.length; i++)
            Signature.internalBinaryWrite(message.signatures[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated consent.common.bff.api.v1.SideEffect side_effects = 10; */
        for (let i = 0; i < message.sideEffects.length; i++)
            SideEffect.internalBinaryWrite(message.sideEffects[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string consent_declined_redirect_url = 11; */
        if (message.consentDeclinedRedirectUrl !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.consentDeclinedRedirectUrl);
        /* consent.common.bff.api.v1.State state = 12; */
        if (message.state !== 0)
            writer.tag(12, WireType.Varint).int32(message.state);
        /* string consent_deferred_redirect_url = 13; */
        if (message.consentDeferredRedirectUrl !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.consentDeferredRedirectUrl);
        /* google.protobuf.Timestamp withdrawn_timestamp = 15; */
        if (message.withdrawnTimestamp)
            Timestamp.internalBinaryWrite(message.withdrawnTimestamp, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string localized_withdrawn_timestamp = 16; */
        if (message.localizedWithdrawnTimestamp !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.localizedWithdrawnTimestamp);
        /* string org_compartment_name = 17; */
        if (message.orgCompartmentName !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.orgCompartmentName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.ConsentRecord
 */
export const ConsentRecord = new ConsentRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Signature$Type extends MessageType<Signature> {
    constructor() {
        super("consent.common.bff.api.v1.Signature", [
            { no: 1, name: "legal_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 4, name: "signing_timestamp", kind: "message", T: () => Timestamp, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "localized_signing_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "signature_image", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 5, name: "reasons", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SigningReason, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 6, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<Signature>): Signature {
        const message = { legalName: "", localizedSigningTimestamp: "", signatureImage: new Uint8Array(0), reasons: [], userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Signature>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Signature): Signature {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string legal_name */ 1:
                    message.legalName = reader.string();
                    break;
                case /* google.protobuf.Timestamp signing_timestamp */ 4:
                    message.signingTimestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.signingTimestamp);
                    break;
                case /* string localized_signing_timestamp */ 2:
                    message.localizedSigningTimestamp = reader.string();
                    break;
                case /* bytes signature_image */ 3:
                    message.signatureImage = reader.bytes();
                    break;
                case /* repeated consent.common.bff.api.v1.SigningReason reasons */ 5:
                    message.reasons.push(SigningReason.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string user_id */ 6:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Signature, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string legal_name = 1; */
        if (message.legalName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.legalName);
        /* google.protobuf.Timestamp signing_timestamp = 4; */
        if (message.signingTimestamp)
            Timestamp.internalBinaryWrite(message.signingTimestamp, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string localized_signing_timestamp = 2; */
        if (message.localizedSigningTimestamp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.localizedSigningTimestamp);
        /* bytes signature_image = 3; */
        if (message.signatureImage.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.signatureImage);
        /* repeated consent.common.bff.api.v1.SigningReason reasons = 5; */
        for (let i = 0; i < message.reasons.length; i++)
            SigningReason.internalBinaryWrite(message.reasons[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string user_id = 6; */
        if (message.userId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.Signature
 */
export const Signature = new Signature$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentMetadata$Type extends MessageType<ConsentMetadata> {
    constructor() {
        super("consent.common.bff.api.v1.ConsentMetadata", [
            { no: 1, name: "consent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "consent_revision", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "internal_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 10, name: "consent_type", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 4, name: "locale_metadata", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocaleMetadata, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 5, name: "signature_metadata", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SignatureMetadata, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 6, name: "irb_approval_date", kind: "message", T: () => Timestamp },
            { no: 7, name: "site_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "principal_investigator_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "key_value_metadata", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KeyValueMetadata, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<ConsentMetadata>): ConsentMetadata {
        const message = { consentId: "", consentRevision: 0n, internalName: "", consentType: "", localeMetadata: [], signatureMetadata: [], siteId: "", principalInvestigatorName: "", keyValueMetadata: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentMetadata): ConsentMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_id */ 1:
                    message.consentId = reader.string();
                    break;
                case /* int64 consent_revision */ 2:
                    message.consentRevision = reader.int64().toBigInt();
                    break;
                case /* string internal_name */ 3:
                    message.internalName = reader.string();
                    break;
                case /* string consent_type */ 10:
                    message.consentType = reader.string();
                    break;
                case /* repeated consent.common.bff.api.v1.LocaleMetadata locale_metadata */ 4:
                    message.localeMetadata.push(LocaleMetadata.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated consent.common.bff.api.v1.SignatureMetadata signature_metadata */ 5:
                    message.signatureMetadata.push(SignatureMetadata.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp irb_approval_date = 6 [deprecated = true];*/ 6:
                    message.irbApprovalDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.irbApprovalDate);
                    break;
                case /* string site_id = 7 [deprecated = true];*/ 7:
                    message.siteId = reader.string();
                    break;
                case /* string principal_investigator_name = 8 [deprecated = true];*/ 8:
                    message.principalInvestigatorName = reader.string();
                    break;
                case /* repeated consent.common.bff.api.v1.KeyValueMetadata key_value_metadata */ 9:
                    message.keyValueMetadata.push(KeyValueMetadata.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsentMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_id = 1; */
        if (message.consentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentId);
        /* int64 consent_revision = 2; */
        if (message.consentRevision !== 0n)
            writer.tag(2, WireType.Varint).int64(message.consentRevision);
        /* string internal_name = 3; */
        if (message.internalName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.internalName);
        /* string consent_type = 10; */
        if (message.consentType !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.consentType);
        /* repeated consent.common.bff.api.v1.LocaleMetadata locale_metadata = 4; */
        for (let i = 0; i < message.localeMetadata.length; i++)
            LocaleMetadata.internalBinaryWrite(message.localeMetadata[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated consent.common.bff.api.v1.SignatureMetadata signature_metadata = 5; */
        for (let i = 0; i < message.signatureMetadata.length; i++)
            SignatureMetadata.internalBinaryWrite(message.signatureMetadata[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp irb_approval_date = 6 [deprecated = true]; */
        if (message.irbApprovalDate)
            Timestamp.internalBinaryWrite(message.irbApprovalDate, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string site_id = 7 [deprecated = true]; */
        if (message.siteId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.siteId);
        /* string principal_investigator_name = 8 [deprecated = true]; */
        if (message.principalInvestigatorName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.principalInvestigatorName);
        /* repeated consent.common.bff.api.v1.KeyValueMetadata key_value_metadata = 9; */
        for (let i = 0; i < message.keyValueMetadata.length; i++)
            KeyValueMetadata.internalBinaryWrite(message.keyValueMetadata[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.ConsentMetadata
 */
export const ConsentMetadata = new ConsentMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignatureMetadata$Type extends MessageType<SignatureMetadata> {
    constructor() {
        super("consent.common.bff.api.v1.SignatureMetadata", [
            { no: 5, name: "signature_actor", kind: "enum", T: () => ["consent.common.bff.api.v1.SignatureActor", SignatureActor, "SIGNATURE_ACTOR_"], options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<SignatureMetadata>): SignatureMetadata {
        const message = { signatureActor: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SignatureMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignatureMetadata): SignatureMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* consent.common.bff.api.v1.SignatureActor signature_actor */ 5:
                    message.signatureActor = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignatureMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* consent.common.bff.api.v1.SignatureActor signature_actor = 5; */
        if (message.signatureActor !== 0)
            writer.tag(5, WireType.Varint).int32(message.signatureActor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.SignatureMetadata
 */
export const SignatureMetadata = new SignatureMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocaleMetadata$Type extends MessageType<LocaleMetadata> {
    constructor() {
        super("consent.common.bff.api.v1.LocaleMetadata", [
            { no: 1, name: "language_code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "key_value_metadata", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => KeyValueMetadata, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<LocaleMetadata>): LocaleMetadata {
        const message = { languageCode: "", title: "", keyValueMetadata: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LocaleMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocaleMetadata): LocaleMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string language_code */ 1:
                    message.languageCode = reader.string();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* repeated consent.common.bff.api.v1.KeyValueMetadata key_value_metadata */ 2:
                    message.keyValueMetadata.push(KeyValueMetadata.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocaleMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string language_code = 1; */
        if (message.languageCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.languageCode);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* repeated consent.common.bff.api.v1.KeyValueMetadata key_value_metadata = 2; */
        for (let i = 0; i < message.keyValueMetadata.length; i++)
            KeyValueMetadata.internalBinaryWrite(message.keyValueMetadata[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.LocaleMetadata
 */
export const LocaleMetadata = new LocaleMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KeyValueMetadata$Type extends MessageType<KeyValueMetadata> {
    constructor() {
        super("consent.common.bff.api.v1.KeyValueMetadata", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "string_value", kind: "scalar", oneof: "value", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "date_value", kind: "message", oneof: "value", T: () => Date }
        ]);
    }
    create(value?: PartialMessage<KeyValueMetadata>): KeyValueMetadata {
        const message = { key: "", value: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KeyValueMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KeyValueMetadata): KeyValueMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string string_value */ 2:
                    message.value = {
                        oneofKind: "stringValue",
                        stringValue: reader.string()
                    };
                    break;
                case /* google.type.Date date_value */ 3:
                    message.value = {
                        oneofKind: "dateValue",
                        dateValue: Date.internalBinaryRead(reader, reader.uint32(), options, (message.value as any).dateValue)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KeyValueMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string string_value = 2; */
        if (message.value.oneofKind === "stringValue")
            writer.tag(2, WireType.LengthDelimited).string(message.value.stringValue);
        /* google.type.Date date_value = 3; */
        if (message.value.oneofKind === "dateValue")
            Date.internalBinaryWrite(message.value.dateValue, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.KeyValueMetadata
 */
export const KeyValueMetadata = new KeyValueMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SideEffect$Type extends MessageType<SideEffect> {
    constructor() {
        super("consent.common.bff.api.v1.SideEffect", [
            { no: 1, name: "write_consent_to_fhir", kind: "message", oneof: "sideEffectOption", T: () => WriteConsentToFhir }
        ]);
    }
    create(value?: PartialMessage<SideEffect>): SideEffect {
        const message = { sideEffectOption: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SideEffect>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SideEffect): SideEffect {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* consent.common.bff.api.v1.WriteConsentToFhir write_consent_to_fhir */ 1:
                    message.sideEffectOption = {
                        oneofKind: "writeConsentToFhir",
                        writeConsentToFhir: WriteConsentToFhir.internalBinaryRead(reader, reader.uint32(), options, (message.sideEffectOption as any).writeConsentToFhir)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SideEffect, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* consent.common.bff.api.v1.WriteConsentToFhir write_consent_to_fhir = 1; */
        if (message.sideEffectOption.oneofKind === "writeConsentToFhir")
            WriteConsentToFhir.internalBinaryWrite(message.sideEffectOption.writeConsentToFhir, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.SideEffect
 */
export const SideEffect = new SideEffect$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteConsentToFhir$Type extends MessageType<WriteConsentToFhir> {
    constructor() {
        super("consent.common.bff.api.v1.WriteConsentToFhir", [
            { no: 1, name: "fhir_store_path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "fhir_store_subject_resource_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WriteConsentToFhir>): WriteConsentToFhir {
        const message = { fhirStorePath: "", fhirStoreSubjectResourceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteConsentToFhir>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteConsentToFhir): WriteConsentToFhir {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fhir_store_path */ 1:
                    message.fhirStorePath = reader.string();
                    break;
                case /* string fhir_store_subject_resource_id */ 2:
                    message.fhirStoreSubjectResourceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WriteConsentToFhir, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fhir_store_path = 1; */
        if (message.fhirStorePath !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fhirStorePath);
        /* string fhir_store_subject_resource_id = 2; */
        if (message.fhirStoreSubjectResourceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.fhirStoreSubjectResourceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.WriteConsentToFhir
 */
export const WriteConsentToFhir = new WriteConsentToFhir$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientConsentContent$Type extends MessageType<ClientConsentContent> {
    constructor() {
        super("consent.common.bff.api.v1.ClientConsentContent", [
            { no: 1, name: "consent_record", kind: "message", T: () => ConsentRecord, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 2, name: "consent_content", kind: "message", T: () => ConsentContent, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ]);
    }
    create(value?: PartialMessage<ClientConsentContent>): ClientConsentContent {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClientConsentContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClientConsentContent): ClientConsentContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* consent.common.bff.api.v1.ConsentRecord consent_record */ 1:
                    message.consentRecord = ConsentRecord.internalBinaryRead(reader, reader.uint32(), options, message.consentRecord);
                    break;
                case /* consent.common.bff.api.v1.ConsentContent consent_content */ 2:
                    message.consentContent = ConsentContent.internalBinaryRead(reader, reader.uint32(), options, message.consentContent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClientConsentContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* consent.common.bff.api.v1.ConsentRecord consent_record = 1; */
        if (message.consentRecord)
            ConsentRecord.internalBinaryWrite(message.consentRecord, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* consent.common.bff.api.v1.ConsentContent consent_content = 2; */
        if (message.consentContent)
            ConsentContent.internalBinaryWrite(message.consentContent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.ClientConsentContent
 */
export const ClientConsentContent = new ClientConsentContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ErrorContent$Type extends MessageType<ErrorContent> {
    constructor() {
        super("consent.common.bff.api.v1.ErrorContent", [
            { no: 1, name: "default_error_message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "back_button_label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "back_button_tooltip", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ErrorContent>): ErrorContent {
        const message = { defaultErrorMessage: "", backButtonLabel: "", backButtonTooltip: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ErrorContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ErrorContent): ErrorContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string default_error_message */ 1:
                    message.defaultErrorMessage = reader.string();
                    break;
                case /* string back_button_label */ 2:
                    message.backButtonLabel = reader.string();
                    break;
                case /* string back_button_tooltip */ 3:
                    message.backButtonTooltip = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ErrorContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string default_error_message = 1; */
        if (message.defaultErrorMessage !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.defaultErrorMessage);
        /* string back_button_label = 2; */
        if (message.backButtonLabel !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.backButtonLabel);
        /* string back_button_tooltip = 3; */
        if (message.backButtonTooltip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.backButtonTooltip);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message consent.common.bff.api.v1.ErrorContent
 */
export const ErrorContent = new ErrorContent$Type();
